export const FILL_PLAYERS_DATA = "FILL_PLAYERS_DATA";
export const FILL_PLAYERS_DATA_LOADING = "FILL_PLAYERS_DATA_LOADING";
export const FILL_PLAYERS_DATA_ERROR = "FILL_PLAYERS_DATA_ERROR";
export const FILL_SESSION_DATA = "FILL_SESSION_DATA";
export const FILL_SESSION_DATA_LOADING = "FILL_SESSION_DATA_LOADING";
export const FILL_SESSION_DATA_ERROR = "FILL_SESSION_DATA_ERROR";
export const FILL_USER_DATA = "FILL_USER_DATA";
export const FILL_USER_DATA_LOADING = "FILL_USER_DATA_LOADING";
export const FILL_USER_DATA_ERROR = "FILL_USER_DATA_ERROR";
export const FILL_LOCATIONS_DATA = "FILL_LOCATIONS_DATA";
export const FILL_LOCATIONS_DATA_LOADING = "FILL_LOCATIONS_DATA_LOADING";
export const FILL_LOCATIONS_DATA_ERROR = "FILL_LOCATIONS_DATA_ERROR";
